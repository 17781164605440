<template>
  <b-card>
    <FormBuilder
      label="Credit Package"
      update
      :data="$store.state.creditPackage.detailData"
      :fields="fields"
      :diff-only="false"
      :loading-detail="$store.state.creditPackage.loadingDetail"
      :loading-submit="$store.state.creditPackage.loadingSubmit"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'amount',
          label: 'Amount',
          type: 'number',
          required: true,
        },
        {
          key: 'discount',
          label: 'Discount',
          type: 'number',
          required: true,
        },
        {
          key: 'price',
          label: 'Price',
          type: 'number',
          required: true,
        },
        {
          key: 'qrCode',
          label: 'QR Code',
          type: 'image',
          required: false,
        },
        {
          key: 'storeProductID',
          label: 'Store Product ID',
        },
        {
          key: 'active',
          label: 'Active',
          type: 'switch',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('creditPackage/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('creditPackage/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('creditPackage/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

<style scoped>
</style>
